import React from 'react';
import { TextImageSection as TextImageSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function TextImageSection(props) {
    return <TextImageSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsTextImageSection on DatoCmsTextImageSection {
        title
        image {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
        cta {
            ...Button
        }
    }
`;
