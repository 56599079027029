import React from 'react';

const PREFIX = 'DatoCms';

export default function Section({ __typename: type, ...props }) {
    let SectionComponent = () => <div> -- Section {type} not found -- </div>;

    switch (type) {
        case `${PREFIX}CardsSquareSection`:
            SectionComponent =
                require('./components/CardsSquareSection').default;
            break;
        case `${PREFIX}CardsSection`:
            SectionComponent = require('./components/CardsSection').default;
            break;
        case `${PREFIX}ContactSection`:
            SectionComponent = require('./components/ContactSection').default;
            break;
        case `${PREFIX}FeaturesSection`:
            SectionComponent = require('./components/FeaturesSection').default;
            break;
        case `${PREFIX}BlogSection`:
            SectionComponent = require('./components/BlogSection').default;
            break;
        case `${PREFIX}SearchBlog`:
            SectionComponent = require('./components/SearchBlog').default;
            break;
        case `${PREFIX}GraphicSection`:
            SectionComponent = require('./components/GraphicSection').default;
            break;
        case `${PREFIX}GridCardsSection`:
            SectionComponent = require('./components/GridCardsSection').default;
            break;
        case `${PREFIX}HeroSection`:
            SectionComponent = require('./components/HeroSection').default;
            break;
        case `${PREFIX}TextImageSection`:
            SectionComponent = require('./components/TextImageSection').default;
            break;
        case `${PREFIX}TextLandscapeSection`:
            SectionComponent =
                require('./components/TextLandscapeSection').default;
            break;
        case `${PREFIX}TextLogosSection`:
            SectionComponent = require('./components/TextLogosSection').default;
            break;
        case `${PREFIX}HtmlSection`:
            SectionComponent = require('./components/HTMLSection').default;
            break;
        default:
            break;
    }

    return <SectionComponent {...props} />;
}
