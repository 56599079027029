import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import { Layout } from '@solublestudio/gatsby-theme-soluble-source';

import Section from '../sections';
import { BlogProvider } from '../context/blogContext';

import {Helmet} from "react-helmet";

export default function Page({ data }) {
    const sections = useMemo(() => data?.datoCmsPage?.sections ?? [], [data]);

    const firstSectionIsContact = useMemo(() => {
        return (
            sections?.length &&
            sections[0].__typename === 'DatoCmsContactSection'
        );
    }, [sections]);

    const isDark = useMemo(() => {
        return (
            (sections?.length &&
                sections[0].__typename === 'DatoCmsHeroSection' &&
                sections[0]?.themeDefault === false) ||
            firstSectionIsContact
        );
    }, [sections, firstSectionIsContact]);

    const showDivider = useMemo(() => {
        const lastItem = sections?.length
            ? sections[sections.length - 1]
            : null;

        if (lastItem) {
            return ![
                'DatoCmsCardsSquareSection',
                'DatoCmsCardsSection',
                'DatoCmsGridCardsSection',
            ].includes(lastItem.__typename);
        }

        return false;
    }, [sections]);

    return (
        <BlogProvider>
            <Layout
                isDark={isDark}
                navbarFixed={true}
                navbarTransparent={true}
                footerExtraParams={{
                    showDivider,
                }}
                {...(firstSectionIsContact &&
                    sections?.length === 1 && {
                        footerExtraParams: { minimal: true },
                    })}>
                {sections.map((section, i) => (
                    <Section
                        key={i}
                        order={i + 1}
                        isLast={i + 1 === sections.length}
                        pageSlug={data?.datoCmsPage?.slug}
                        {...(firstSectionIsContact &&
                            !i && { dataTheme: 'bg-primary' })}
                        {...section}
                    />
                ))}
                <Helmet>
            <script>{`
                (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-575VDKC9');
            `}
            </script>
        </Helmet>
            </Layout>
        </BlogProvider>
    );
}

export const query = graphql`
    fragment Button on DatoCmsButton {
        label
        detached
        externalLink
        link {
            slug
        }
    }

    query Page($id: String, $language: String) {
        datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
            title
            sections {
                ... on DatoCmsFeaturesSection {
                    __typename
                    ...DatoCmsFeaturesSection
                }
                ... on DatoCmsTextImageSection {
                    __typename
                    ...DatoCmsTextImageSection
                }
                ... on DatoCmsBlogSection {
                    __typename
                    ...DatoCmsBlogSection
                }
                ... on DatoCmsCardsSection {
                    __typename
                    ...DatoCmsCardsSection
                }
                ... on DatoCmsGridCardsSection {
                    __typename
                    ...DatoCmsGridCardsSection
                }
                ... on DatoCmsCardsSquareSection {
                    __typename
                    ...DatoCmsCardsSquareSection
                }
                ... on DatoCmsHeroSection {
                    __typename
                    ...DatoCmsHeroSection
                }
                ... on DatoCmsContactSection {
                    __typename
                    ...DatoCmsContactSection
                }
                ... on DatoCmsTextLandscapeSection {
                    __typename
                    ...DatoCmsTextLandscapeSection
                }
                ... on DatoCmsTextLogosSection {
                    __typename
                    ...DatoCmsTextLogosSection
                }
                ... on DatoCmsHtmlSection {
                    __typename
                    ...DatoCmsHtmlSection
                }
                ... on DatoCmsGraphicSection {
                    __typename
                    ...DatoCmsGraphicSection
                }
                ... on DatoCmsSearchBlog {
                    __typename
                    ...DatoCmsSearchBlog
                }
            }
        }
    }
`;
