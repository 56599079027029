import React from 'react';
import { GridCardsSection as GridCardsSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function GridCardsSection(props) {
    return <GridCardsSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsCardImage on DatoCmsCardImage {
        internal {
            type
        }
        image {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
        title
        category
        link {
            ...Button
        }
    }

    fragment DatoCmsGridCardsSection on DatoCmsGridCardsSection {
        pretitle
        title
        items {
            ... on DatoCmsCardImage {
                ...DatoCmsCardImage
            }
        }
    }
`;
