import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { Container, Row, Col } from '@solublestudio/soluto-design-system';
import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';
import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import { BlogContext } from '../../../context/blogContext';
import FeaturedBlog from '../../FeaturedBlog';

const BlogSection = ({ title, blogs }) => {
    const { posts } = useContext(BlogContext);
    const [pagination, setPagination] = useState(6);
    const [windowWidth, setWindowWidth] = useState(
        typeof window !== 'undefined' ? window.innerWidth : null,
    );

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const featuresPost = blogs?.filter((item) => item.featured === true);

    const featuredPostComponent =
        featuresPost.length > 0
            ? featuresPost
                  .slice(0, 3)
                  .map((item) => <FeaturedBlog item={item} />)
            : blogs?.slice(0, 3).map((item) => <FeaturedBlog item={item} />);

    return (
        <Section>
            <Container>
                <Heading
                    component="h3"
                    font="h300"
                    style={{
                        display: '-webkit-box',
                        '-webkit-line-clamp': '2',
                        '-webkit-box-orient': 'vertical',
                        lineHeight: '35px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'wrap',
                        paddingBottom: '25px',
                    }}>
                    Featured
                </Heading>
                <Row
                    style={{
                        width: '100%',
                        paddingTop: '25px',
                        marginTop: '25px',
                    }}>
                    {featuredPostComponent}
                </Row>
            </Container>
            <Container>
                {title && (
                    <Row>
                        <Col col={{ lg: 7 }}>
                            {title && <TitleComponent title={title} />}
                        </Col>
                    </Row>
                )}
                <Col
                    className="mt-xxl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    }}>
                    {posts.slice(0, pagination).map((item, index) => {
                        return (
                            <Link
                                to={`/blog/${item.slug}`}
                                state={{
                                    id: item.id,
                                }}
                                key={index}>
                                <Col
                                    key={index}
                                    col={{ lg: 4 }}
                                    className="mb-xxxl"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        maxWidth: '100%',
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    }}>
                                    <div
                                        className={cls('mb-xxs')}
                                        style={{
                                            display: 'flex',
                                            flexDirection:
                                                windowWidth <= 650
                                                    ? 'column'
                                                    : index % 2 === 0
                                                    ? 'row'
                                                    : 'row-reverse',
                                            width: '100%',
                                            gap: 20,
                                        }}>
                                        <img
                                            src={item.coverImage.fluid.src}
                                            alt={item.titulo}
                                            style={{
                                                width:
                                                    windowWidth <= 650
                                                        ? '100%'
                                                        : '50%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div
                                            style={{
                                                width:
                                                    windowWidth <= 650
                                                        ? '100%'
                                                        : '50%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                gap: 20,
                                            }}>
                                            <h2
                                                style={{
                                                    fontSize: '1.8rem',
                                                    lineHeight:
                                                        windowWidth <= 650
                                                            ? '2rem'
                                                            : '2.5rem',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    '-webkit-line-clamp': '2',
                                                    '-webkit-box-orient':
                                                        'vertical',
                                                    whiteSpace: 'wrap',
                                                }}>
                                                {item.titulo}
                                            </h2>
                                            <h3
                                                style={{
                                                    fontSize: '1.2rem',
                                                }}>
                                                {item.author.name} | {item.date}{' '}
                                                |{' '}
                                                {item.categories
                                                    .map((item) => item.name)
                                                    .join(' ')}
                                            </h3>
                                            <h3
                                                style={{
                                                    fontSize: '1.2rem',
                                                }}>
                                                {item.excerpt}
                                            </h3>
                                        </div>
                                    </div>
                                </Col>
                            </Link>
                        );
                    })}
                </Col>
                {posts.length >= pagination && (
                    <button
                        style={{
                            width: 200,
                            height: 50,
                            backgroundColor: '#8EDD65',
                            border: '1px solid #110079',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            margin: '0 auto',
                            marginBottom: 100,
                        }}
                        onClick={() => {
                            setPagination(pagination + 6);
                        }}>
                        <h3>SHOW MORE</h3>
                    </button>
                )}
            </Container>
        </Section>
    );
};

export default BlogSection;
