import React from 'react';
import { TextLandscapeSection as TextLandscapeSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function TextLandscapeSection(props) {
    return <TextLandscapeSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsTextLandscapeSection on DatoCmsTextLandscapeSection {
        pretitle
        title
        description
        image {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
    }
`;
