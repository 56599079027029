/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useState } from 'react';
import { Container, Row } from '@solublestudio/soluto-design-system';
import Section from '@ds/components/shared/Section';
import { BlogContext } from '../../../context/blogContext';
import SearchIcon from '../../../img/search.png';

const index = (props) => {
    const { categories, search, setFilter, setSearch, filter } =
        useContext(BlogContext);
    const { category } = filter;
    const filterLabel = { label: 'Categories' };
    const [hover, setHover] = useState(false);
    const [hoverSearch, setHoverSearch] = useState(false);

    return (
        <Section
            style={{
                paddingBottom: 0,
            }}>
            <Container>
                <Row>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                        <button
                            onClick={() => {
                                setHover(!hover);
                            }}
                            style={{
                                position: 'relative',
                                height: 50,
                                padding: '0 30px',
                                backgroundColor: hover ? '#8EDD65' : '#8EDD65',
                                border: '1px solid #110079',
                            }}>
                            {filterLabel.label}
                        </button>
                        <div
                            style={{
                                position: 'absolute',
                                top: 60,
                                left: 0,
                                width: 300,
                                height: 300,
                                padding: '50px 50px',
                                margin: 0,
                                backgroundColor: '#8EDD65',
                                zIndex: 1,
                                display: hover ? 'flex' : 'none',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                                gap: 10,
                                borderWidth: 1,
                                borderColor: '#110079',
                                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                            }}>
                            {categories.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setHover(false);
                                            setFilter(item.name);
                                        }}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: 50,
                                            zIndex: 1,
                                            cursor: 'pointer',
                                            padding: '0 30px',
                                            borderRadius: 20,
                                            backgroundColor:
                                                item.name === category
                                                    ? '#fff'
                                                    : '#8EDD65',
                                            fontWeight:
                                                item.name === category
                                                    ? 'bold'
                                                    : 'normal',
                                            // textDecoration:
                                            //     item.name === category
                                            //         ? 'underline'
                                            //         : 'none',
                                        }}>
                                        {item.name}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            onFocus={() => {
                                setHoverSearch(true);
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '25rem',
                                height: 50,
                                backgroundColor: hoverSearch
                                    ? '#fff'
                                    : '#8EDD65',
                                border: '1px solid #110079',
                            }}>
                            <input
                                type="text"
                                placeholder="Search"
                                style={{
                                    padding: '0 30px',
                                    width: '100%',
                                    height: 48,
                                    border: 'none',
                                    outline: 'none',
                                }}
                                value={search.query}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            {search.query === '' || search.query === null ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 60,
                                        backgroundColor: '#8EDD65',
                                        cursor: 'pointer',
                                        borderColor: '#110079',
                                        borderLeftWidth: 1,
                                        borderLeftStyle: 'solid',
                                    }}>
                                    <img
                                        src={SearchIcon}
                                        alt="search"
                                        title="search"
                                        style={{
                                            width: 30,
                                        }}
                                    />
                                </div>
                            ) : (
                                <button
                                    onClick={() => {
                                        setSearch('');
                                    }}
                                    style={{
                                        padding: '0 20px',
                                        backgroundColor: '#110079',
                                        color: '#fff',
                                        border: 'none',
                                    }}>
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    );
};

export default index;
