import React from 'react';
import { TextLogosSection as TextLogosSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function TextLogosSection(props) {
    return <TextLogosSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsTextLogosSection on DatoCmsTextLogosSection {
        pretitle
        title
        logos {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
    }
`;
