import React from 'react';
import { GraphicSection as GraphicSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function GraphicSection(props) {
    return <GraphicSectionDS {...props} image={props.image.file.publicURL} />;
}

export const query = graphql`
    fragment DatoCmsGraphicSection on DatoCmsGraphicSection {
        title
        image {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`;
