/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useReducer } from 'react';

const initialState = {
    posts: [],
    postsOriginal: {},
    categories: [{ name: 'All' }],
    loading: true,
    error: null,
    filter: {
        category: null,
    },
    search: {
        query: null,
    },
};

export const BlogContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_POSTS':
            return {
                ...state,
                posts: action.payload,
            };
        case 'SET_POSTS_ORIGINAL':
            return {
                ...state,
                postsOriginal: action.payload,
            };
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: [...state.categories, ...action.payload],
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'SET_FILTER':
            return {
                ...state,
                filter: {
                    category: action.payload,
                },
            };
        case 'SET_SEARCH':
            return {
                ...state,
                search: {
                    query: action.payload,
                },
            };
        default:
            return state;
    }
};

const setAction = (type, payload) => ({
    type,
    payload,
});

export const BlogProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setPosts = (payload) => dispatch(setAction('SET_POSTS', payload));
    const setPostsOriginal = (payload) =>
        dispatch(setAction('SET_POSTS_ORIGINAL', payload));
    const setCategories = (payload) =>
        dispatch(setAction('SET_CATEGORIES', payload));
    const setLoading = (payload) => dispatch(setAction('SET_LOADING', payload));
    const setError = (payload) => dispatch(setAction('SET_ERROR', payload));
    const setFilter = (payload) => dispatch(setAction('SET_FILTER', payload));
    const setSearch = (payload) => dispatch(setAction('SET_SEARCH', payload));

    const value = {
        ...state,
        setPosts,
        setPostsOriginal,
        setCategories,
        setLoading,
        setError,
        setFilter,
        setSearch,
    };

    useEffect(() => {
        if (state.filter.category) {
            if (
                state.filter.category === null ||
                state.filter.category === 'All'
            ) {
                setPosts(state.postsOriginal);
                return;
            }
            const filterPost = state.postsOriginal.filter((post) =>
                post.categories.some(
                    (category) => category.name === state.filter.category,
                ),
            );
            setPosts(filterPost);
        }
    }, [state.filter.category]);

    useEffect(() => {
        if (state.search.query) {
            if (state.search.query === null || state.search.query === '') {
                setPosts(state.postsOriginal);
                return;
            }
            const filterPost = state.postsOriginal.filter((post) =>
                post.titulo
                    .toLowerCase()
                    .includes(state.search.query.toLowerCase()),
            );
            setPosts(filterPost);
        }
    }, [state.search.query]);

    return (
        <BlogContext.Provider value={value}>{children}</BlogContext.Provider>
    );
};
