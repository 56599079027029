/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import BlogJX from './Section'
import { graphql } from 'gatsby'
import { BlogContext } from '../../context/blogContext'

export default function BlogSection(props: JSX.IntrinsicAttributes & { title: any; blogs: any }) {
    const { setPosts, setPostsOriginal, setCategories, setFilter } = useContext(BlogContext)

    useEffect(() => {
        if (props?.blogs !== null && props?.blogs !== undefined && props?.blogs.length > 0) {
            setPosts(props?.blogs)
            setPostsOriginal(props?.blogs)
            const categories = props?.blogs.flatMap((blog: { categories: any[] }) => blog.categories.map((category: { name: any }) => category.name))
            const unique = [...new Set(categories)]
            const uniqueCategories = unique.map((category) => {
                return {
                    name: category,
                    active: false,
                }
            })
            setCategories(uniqueCategories)
            setFilter('All')
        }
    }, [])

    return <BlogJX {...props} />
}

export const query = graphql`
    fragment DatoCmsBlog on DatoCmsBlog {
        id
        titulo
        slug
        author {
            name
        }
        date
        excerpt
        featured
        categories {
            name
        }
        coverImage {
            fluid {
                ...GatsbyDatoCmsFluid
            }
        }
    }

    fragment DatoCmsBlogSection on DatoCmsBlogSection {
        blogs {
            ... on DatoCmsBlog {
            ...DatoCmsBlog
            }
            categories {
                name
            }
        }
    }
`

