import React from 'react';
import { CardsSquareSection as CardsSquareSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function CardSquareSection(props) {
    return <CardsSquareSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsCardSquare on DatoCmsCardSquare {
        text
    }

    fragment DatoCmsCardsSquareSection on DatoCmsCardsSquareSection {
        pretitle
        title
        items {
            ... on DatoCmsCardSquare {
                ...DatoCmsCardSquare
            }
        }
        background {
            isImage
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`;
