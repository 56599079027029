import React from 'react'
import Search from './Section'
import { graphql } from 'gatsby'

const index = (props) => {
  return (
    <Search {...props} />
  )
}

export default index

export const query = graphql`
    fragment DatoCmsSearchBlog on DatoCmsSearchBlog {
        id
    }

`


