import React from 'react'
import { FeaturesSection as FeaturesSectionDS } from '@solublestudio/adaion-design-system'
import { graphql } from 'gatsby'

export default function FeaturesSection(props) {
    return <FeaturesSectionDS {...props} />
}

export const query = graphql`
    fragment DatoCmsFeature on DatoCmsFeature {
        icon
        title
        description
        blogImage {
            fluid {
                ...GatsbyDatoCmsFluid
            }
        }
    }

    fragment DatoCmsFeaturesSection on DatoCmsFeaturesSection {
        title
        subtitle
        features {
            ... on DatoCmsFeature {
                ...DatoCmsFeature
            }
        }
        cta {
            ...Button
        }
    }
`
