import React from 'react';
import { HTMLSection as HTMLSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function HTMLSection(props) {
    return <HTMLSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsHtmlSection on DatoCmsHtmlSection {
        title
        text
    }
`;
