import React from 'react';
import { CardsSection as CardsSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';

export default function CardsSection(props) {
    return <CardsSectionDS {...props} />;
}

export const query = graphql`
    fragment DatoCmsCardContent on DatoCmsCardContent {
        internal {
            type
        }
        image {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
        title
        description
        link {
            ...Button
        }
    }

    fragment DatoCmsCardsSection on DatoCmsCardsSection {
        pretitle
        title
        description
        fullImage
        items {
            ... on DatoCmsCardContent {
                ...DatoCmsCardContent
            }
        }
        cta {
            ...Button
        }
    }
`;
