import React from 'react';
import { ContactSection as ContactSectionDS } from '@solublestudio/adaion-design-system';
import { graphql } from 'gatsby';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

export default function ContactSection(props) {
    const translation = useTranslate();

    return (
        <ContactSectionDS
            {...props}
            categories={translation['categories'].text}
        />
    );
}

export const query = graphql`
    fragment DatoCmsContactSection on DatoCmsContactSection {
        title
        formId
        text
        textForm
        viewBar
    }
`;
