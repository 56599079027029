import React, { useState } from 'react';
import { Link } from 'gatsby';

import { Col } from '@solublestudio/soluto-design-system';
import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

const FeaturedBlog = ({ item }) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    return (
        <Col
            key={item.id}
            col={{ lg: 4 }}
            className="mb-xxl"
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '25px',
                paddingBottom: '25px',
                backgroundColor: mouseEnter ? '#110079' : '#8EDD65',
                width: '100%',
            }}>
            <Link
                to={`/blog/${item.slug}`}
                onMouseEnter={() => {
                    setMouseEnter(true);
                }}
                onMouseLeave={() => {
                    setMouseEnter(false);
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 10,
                    }}>
                    {item.coverImage && (
                        <img
                            src={item.coverImage.fluid.src}
                            alt={item.titulo}
                            style={{
                                width: '100%',
                                height: 'auto',
                            }}
                        />
                    )}
                    <div className={cls('d-flex')}>
                        <Heading
                            component="h4"
                            font="h100"
                            style={{
                                display: '-webkit-box',
                                '-webkit-line-clamp': '2',
                                '-webkit-box-orient': 'vertical',
                                lineHeight: '35px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'wrap',
                            }}>
                            {item.titulo}
                        </Heading>
                    </div>
                    <h3
                        style={{
                            fontSize: '0.8rem',
                        }}>
                        {item.author.name} | {item.date} |{' '}
                        {item.categories.map((item) => item.name).join(' ')}
                    </h3>
                    <Text component="div" font="b300">
                        {item.excerpt}
                    </Text>
                </div>
            </Link>
        </Col>
    );
};

export default FeaturedBlog;
